import { useTranslation } from 'react-i18next'

export const useBrandName = () => {
  const { t } = useTranslation()

  if (process.env.BRAND === 'escalon') {
    return t('brand::escalon')
  }

  return t('brand::settle')
}
