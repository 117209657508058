import { useTranslation } from 'react-i18next'
import { Controller, type ControllerProps, type FieldValues } from 'react-hook-form'
import type { FieldPath } from 'react-hook-form/dist/types'
import { TextField, type ITextFieldProps } from 'settle-ui/components/TextField/TextField'
import { mergeRefs } from 'lib/helpers/mergeRefs/mergeRefs'

type TController<T extends FieldValues, TName extends FieldPath<T>> = Pick<
  ControllerProps<T, TName>,
  'defaultValue' | 'shouldUnregister' | 'name' | 'rules' | 'control'
>
export type TTextFieldControllerProps<T extends FieldValues, TName extends FieldPath<T>> = ITextFieldProps &
  TController<T, TName> & {
    'data-test-id'?: string
  }

export type TTextFieldController = <T extends FieldValues, TName extends FieldPath<T>>(
  props: TTextFieldControllerProps<T, TName>,
) => JSX.Element

export const TextFieldController: TTextFieldController = ({
  id,
  name,
  rules,
  control,
  variant,
  inputRef,
  defaultValue,
  size = 'medium',
  shouldUnregister,
  required = true,
  fullWidth = true,
  margin = 'normal',
  color = 'secondary',
  type = 'text',
  ...restTextFieldProps
}) => {
  const { t } = useTranslation()

  return (
    <Controller
      data-test-id={id}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
      name={name}
      rules={{
        required: { value: required, message: t('validation::cantBeEmpty') },
        ...rules,
      }}
      control={control}
      render={({ field: { ref, ...fieldProps }, fieldState: { error } }) => {
        const helperText = error ? error.message : restTextFieldProps.helperText

        return (
          <TextField
            data-test-id={id}
            {...fieldProps}
            {...restTextFieldProps}
            size={size}
            inputRef={mergeRefs([ref, inputRef])}
            margin={margin}
            variant={variant}
            required={required}
            fullWidth={fullWidth}
            helperText={helperText}
            type={type}
            error={Boolean(error) || restTextFieldProps.error}
          />
        )
      }}
    />
  )
}
